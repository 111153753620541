import {
  staticPath_assets_media_common_hand_with_smartphone_png,
  staticPath_assets_illusts_shindan_woman_shindan_start_svg,
  staticPath_assets_sns_line_add_friend_png,
  staticPath_assets_sns_line_icon_png,
} from "lib/$asset-path"
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  AccordionProps,
  Box,
  Button,
  Center,
  chakra,
  HStack,
  Stack,
  useAccordionItemState,
  VStack,
} from "@chakra-ui/react"
import { CheckList, CheckListItem } from "@~/common/components/ui/CheckList"
import { OwataLabel } from "components/lp/widgets/OwataLabel"
import { Break, NextImage, NextImageInlineWrapper } from "components/system"

import React from "react"

export type LineBannerProps = React.ComponentProps<typeof LineBanner>

export const LineBanner = ({ ...props }: AccordionProps) => {
  return (
    <Accordion
      allowToggle
      defaultIndex={[0]}
      w="full"
      maxW="container.contentSm"
      pt={2}
      {...props}
    >
      <AccordionItem
        border="2px solid #06c755"
        borderRadius="m"
        _last={{ borderBottomWidth: "2px" }}
      >
        <chakra.h3>
          <AccordionButton p={2} pl="42px" pos="relative">
            <chakra.div flex={1} textStyle="14-bold" textAlign="left">
              お金の豆知識をLINEで無料発信中
            </chakra.div>
            <AccordionIcon boxSize="24px" />
            <Box pos="absolute" left="-10px" top="-17px">
              <NextImage
                src={staticPath_assets_media_common_hand_with_smartphone_png}
                width={42}
                height={64}
                alt=""
              />
            </Box>
            <SpeechBubble />
          </AccordionButton>
        </chakra.h3>
        <AccordionPanel p={0} mt="2px">
          <Content />
        </AccordionPanel>
      </AccordionItem>
    </Accordion>
  )
}

const SpeechBubble = () => {
  return (
    <Box pos="absolute" left="42px" top="-15px">
      <chakra.div
        bgColor="#06c755"
        textStyle="12-default-bold"
        color="white"
        px="8px"
        py="3px"
        borderRadius="30px"
        pos="relative"
        _after={{
          content: `""`,
          border: "solid transparent",
          borderRightWidth: "8px",
          borderRightColor: "#06c755",
          borderLeftWidth: "8px",
          borderTopWidth: "4px",
          borderBottomWidth: "4px",
          marginTop: "-4px",
          transform: "rotate(-20deg)",
          position: "absolute",
          right: "98%",
          top: "85%",
        }}
      >
        つみたてNISA? iDeCo?
      </chakra.div>
    </Box>
  )
}

const Content = () => {
  const { onClose } = useAccordionItemState()

  return (
    <Box>
      <VStack spacing={1} bgColor="#06c755" py={6}>
        <OwataLabel
          labelProps={{ textStyle: "12-bold", color: "white", px: 0 }}
          handProps={{ w: "12px", h: "1px", bgColor: "white" }}
          mb={1}
        >
          マネイロLINE公式アカウントで
        </OwataLabel>
        <chakra.div textStyle="24-bold" textAlign="center" color="white">
          お金と投資の
          <br />
          <chakra.span color="#fff170">正しい知識</chakra.span>が身につく！
        </chakra.div>
      </VStack>
      <Stack
        spacing={2}
        bgColor="moneiro-color-fill-gray-basic"
        px={3}
        py={6}
        pos="relative"
      >
        <chakra.div textStyle="18-bold">「友だち」登録すると…</chakra.div>
        <NextImageInlineWrapper pos="absolute" right="12px" top="-37px">
          <NextImage
            src={staticPath_assets_illusts_shindan_woman_shindan_start_svg}
            width={95}
            height={95}
            alt=""
            unoptimized
          />
        </NextImageInlineWrapper>
        <CheckList
          spacing={2}
          bgColor="moneiro-color-white"
          p={4}
          borderRadius="m"
          boxShadow="0px 1px 5px rgba(0, 0, 0, 0.15)"
        >
          {LIST_ITEMS.map((item, idx) => {
            return (
              <CheckListItem key={idx} textStyle="16-bold">
                {item}
              </CheckListItem>
            )
          })}
        </CheckList>
      </Stack>
      <VStack
        spacing={{ base: 4, md: 6 }}
        bgColor="#06c755"
        px={{ base: 6, md: 3 }}
        pt={6}
        pb={4}
        borderBottomRadius="3px"
        pos="relative"
      >
        <chakra.div textStyle="18-bold" color="white" mb={{ base: 0, md: 3 }}>
          友だち登録の始めかた
        </chakra.div>
        <NextImageInlineWrapper
          pos="absolute"
          left="5px"
          top={{ base: "-50px", md: "-65px" }}
        >
          <NextImage
            src={staticPath_assets_sns_line_add_friend_png}
            width={105}
            height={78}
            alt=""
          />
        </NextImageInlineWrapper>
        <Stack
          spacing={{ base: 4, md: 2 }}
          direction={{ base: "column", md: "row" }}
          w="full"
        >
          {FLOW_ITEMS.map((item, idx) => {
            return <FlowCard {...item} idx={idx} key={idx} />
          })}
        </Stack>
        <Button
          onClick={onClose}
          variant="unstyled"
          h="auto"
          fontWeight="700"
          fontSize="14px"
          color="white"
        >
          閉じる
        </Button>
      </VStack>
    </Box>
  )
}

const LIST_ITEMS = [
  <>
    <chakra.span color="moneiro-color-main-orange">お金の基礎知識</chakra.span>
    や<chakra.span color="moneiro-color-main-orange">最新情報</chakra.span>
    をお届け
  </>,
  <>
    あなたは将来お金に困る？困らない？
    <chakra.span color="moneiro-color-main-orange">3分投資診断</chakra.span>
    を受けられる
  </>,
  <>
    お金のプロが
    <chakra.span color="moneiro-color-main-orange">無料</chakra.span>
    でアドバイス
    <br />
    オンライン相談を簡単に予約できる
  </>,
]

const FlowCard = ({ idx, header, body }: { idx: number } & FlowItem) => {
  return (
    <Box layerStyle="base" w="full" pos="relative">
      <Center
        boxSize="52px"
        bgColor="moneiro-color-darkblue"
        color="white"
        borderRadius="50%"
        pos="absolute"
        left={{ base: "-26px", md: "36%" }}
        top={{ base: "-13px", md: "-33px" }}
      >
        <chakra.div textStyle="18-bold" lineHeight="18px" textAlign="center">
          <chakra.div textStyle="11-default-bold" lineHeight="11px" mb="2px">
            方法
          </chakra.div>
          {idx + 1}
        </chakra.div>
      </Center>
      <chakra.div
        textStyle={{ base: "16-bold", md: "18-bold" }}
        textAlign={{ base: "center", md: "start" }}
        p={2}
        pt={{ base: 2, md: 4 }}
        borderBottomWidth="2px"
        borderBottomColor="moneiro-color-line-gray-basic"
      >
        {header}
      </chakra.div>
      <Center minH={{ base: "70px", md: "200px" }}>{body}</Center>
    </Box>
  )
}

type FlowItem = {
  header: string
  body: React.ReactNode
}

/** LINE追加用リンク
 * 経路: マネイロメディア
 * 流入元: バナー
 */
const LINE_URL_FROM_MEDIA = "https://lin.ee/r85PtWd"
const LINE_QR_URL_FROM_MEDIA =
  "https://qr-official.line.me/sid/M/549krhbb.png?shortenUrl=true&appendQueryParameter=oat__id%3D678774"

const FLOW_ITEMS: FlowItem[] = [
  {
    header: "スマホの方はこちらから",
    body: (
      <chakra.button
        as="a"
        href={LINE_URL_FROM_MEDIA}
        target="_blank"
        rel="noopener"
      >
        <HStack spacing={0} bgColor="#06c755" pr={2} pl={1} borderRadius="m">
          <NextImageInlineWrapper>
            <NextImage
              src={staticPath_assets_sns_line_icon_png}
              width={44}
              height={44}
              alt="LINE"
            />
          </NextImageInlineWrapper>
          <chakra.div fontWeight="700" fontSize="22px" color="white">
            友だち追加
          </chakra.div>
        </HStack>
      </chakra.button>
    ),
  },
  {
    header: "パソコンの方はこちらから",
    body: (
      <VStack spacing={1} py={2}>
        <chakra.div textStyle="12-bold">
          LINEアプリで[友だち追加]→
          <Break mdOnly />
          [QRコード]
          <br />
          下のQRコードを読み取る
        </chakra.div>
        <NextImageInlineWrapper>
          <NextImage
            src={LINE_QR_URL_FROM_MEDIA}
            width={105}
            height={105}
            layout="fixed"
            unoptimized
            alt="LINEの友だち追加用QRコード"
          />
        </NextImageInlineWrapper>
      </VStack>
    ),
  },
  {
    header: "LINEアプリでLINE ID検索",
    body: <chakra.div textStyle="18-bold">@moneiro</chakra.div>,
  },
]

import { Box, chakra, Stack, StackProps } from "@chakra-ui/react"
import checkboxPng from "@~/assets/lp/common/checkbox.png"
import React from "react"
import { NextImage, NextImageProps } from "../../system"

export type CheckListProps = React.PropsWithChildren<StackProps>

export const CheckList = ({ children, ...props }: CheckListProps) => {
  return (
    <Stack as="ul" spacing={0} listStyleType="none" {...props}>
      {children}
    </Stack>
  )
}

export type CheckListItemProps = React.PropsWithChildren<
  {
    imageProps?: NextImageProps
  } & StackProps
>

export const CheckListItem = ({
  children,
  imageProps,
  ...props
}: CheckListItemProps) => {
  return (
    <Stack as="li" direction="row" spacing={1} {...props}>
      <Box flexShrink={0}>
        <NextImage
          src={checkboxPng}
          width={18}
          height={19}
          alt=""
          {...imageProps}
        />
      </Box>
      <chakra.div>{children}</chakra.div>
    </Stack>
  )
}
